// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-document-tsx": () => import("./../src/templates/document.tsx" /* webpackChunkName: "component---src-templates-document-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-plusuno-koukoku-2017-tsx": () => import("./../src/pages/__/plusuno/_koukoku/2017.tsx" /* webpackChunkName: "component---src-pages-plusuno-koukoku-2017-tsx" */),
  "component---src-pages-company-tsx": () => import("./../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-en-company-tsx": () => import("./../src/pages/en/company.tsx" /* webpackChunkName: "component---src-pages-en-company-tsx" */),
  "component---src-pages-en-contact-tsx": () => import("./../src/pages/en/contact.tsx" /* webpackChunkName: "component---src-pages-en-contact-tsx" */),
  "component---src-pages-en-job-tsx": () => import("./../src/pages/en/job.tsx" /* webpackChunkName: "component---src-pages-en-job-tsx" */),
  "component---src-pages-en-service-tsx": () => import("./../src/pages/en/service.tsx" /* webpackChunkName: "component---src-pages-en-service-tsx" */),
  "component---src-pages-en-thanks-tsx": () => import("./../src/pages/en/thanks.tsx" /* webpackChunkName: "component---src-pages-en-thanks-tsx" */),
  "component---src-pages-job-tsx": () => import("./../src/pages/job.tsx" /* webpackChunkName: "component---src-pages-job-tsx" */),
  "component---src-pages-service-tsx": () => import("./../src/pages/service.tsx" /* webpackChunkName: "component---src-pages-service-tsx" */),
  "component---src-pages-thanks-tsx": () => import("./../src/pages/thanks.tsx" /* webpackChunkName: "component---src-pages-thanks-tsx" */)
}

