import { colors } from '@src/theme/colors'
import { pages } from '@config/pages'

// envrinment
export const GoogleAnalytics = {
  trackingId: 'UA-96179613-1',
}
export const GoogleMap = {
  placeId: 'ChIJSXcgydyMGGAR63elql-vXhs',
  API_KEY:
    process.env.NODE_ENV === 'development'
      ? ''
      : 'AIzaSyC9IauuEKNSnhN-kPBRCBpWZ4i2DKQBsQc',
  zoom: 14, // 4 for international, 14 for internal
}
export const SLACK_WEBHOOK_URL =
  'https://hooks.slack.com/services/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'
export const DEFAULT_EMAIL = 'noreply@plusuno.jp'
export const ADMIN_EMAIL = 'k.o1@plusuno.jp'
export const DOMAIN = 'plusuno.jp'
export const SENDGRID_API_KEY =
  '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'

// SEO
export const siteTitle = {
  ja: {
    default: 'Plusuno Inc. - "+1" for our life',
    template: 'Plusuno Inc.',
    templateSeparator: ' | ', // ["|", "-"]
    templatePosition: 'end', // ['start', 'end']
  },
  en: {
    default: 'Plusuno Inc. - "+1" for our life',
    template: 'Plusuno Inc.',
    templateSeparator: ' | ', // ["|", "-"]
    templatePosition: 'end', // ['start', 'end']
  },
}
export const twitter = ''
export const facebookAppId = ''
export const siteUrl = 'https://www.plusuno.jp'
export const sitemap = `${siteUrl}/sitemap.xml`
export const defaultLocale = 'ja'
const keywords = 'Plusuno, Plusuno Inc., '

interface localesPath {
  ja: string
  en: string
  [key: string]: string
}
export type localeType = 'ja' | 'en'
export const locales: localesPath = {
  en: '/en',
  ja: '',
}

export const shortName = 'Plusuno'
export const companyName = 'Plusuno Inc.'
export const companyNameJp = 'ぷらすの株式会社'
export const address = {
  ja: '東京都新宿区新宿2-12-13 アントレサロンビル2F',
  en:
    '2-12-13, SHINJUKU<br />SHINJUKU ANTRE SALON BLDG. 2F.<br />SHINJUKU-KU, TOKYO 160-0022',
}
export const Address = {
  position: { lat: 35.6906018, lng: 139.7079907 },
}

export const companyNumber = '2011101079929'
const description =
  'Plusuno is a lifestyle development company. We create apps to help life fun, comfortable & stressless.'

const author = companyName
const companyInfo: any = {
  en: [
    {
      name: 'Name',
      content: companyName,
    },
    {
      name: 'Corporate Number',
      content: companyNumber,
    },
    {
      name: 'Address',
      content: address.en,
    },
  ],
  ja: [
    {
      name: '会社名',
      content: companyNameJp,
    },
    {
      name: '法人番号',
      content: companyNumber,
    },
    {
      name: '所在地',
      content: address.ja,
    },
  ],
}

const site = {
  title: siteTitle,
  keywords,
  description,
  url: siteUrl,
  sitemap,
  manifest: {
    name: companyName,
    short_name: shortName,
    description,
    start_url: '/',
    background_color: colors.background,
    theme_color: colors.theme,
    display: 'minimal-ui',
    orientation: 'portrait',
  },
}

export { pages, author, companyInfo, site }
