export const pages = [
  {
    path: '/',
    title: 'ぷらすの株式会社 - "+1" for our life',
    // description: '',
    keyword: 'ぷらすの, ぷらすの株式会社',
  },
  {
    path: '/company',
    title: '会社概要 - ぷらすの株式会社',
    keyword: 'ぷらすの, ぷらすの株式会社, 会社概要,',
  },
  {
    path: '/service',
    title: 'サービス - ぷらすの株式会社',
  },
  {
    path: '/job',
    title: '採用情報 - ぷらすの株式会社',
  },
  {
    path: '/contact',
    title: 'お問い合わせ - ぷらすの株式会社',
  },

  {
    path: '/en/',
    title: 'Plusuno Inc. - "+1" for our life',
    description:
      'Plusuno is a lifestyle development company. We create apps to help life fun, comfortable & stressless.',
    keyword: 'Plusuno, Plusuno Inc.',
  },
  {
    path: '/en/company',
    title: 'Company Information - Plusuno Inc.',
    keyword: 'Plusuno, Plusuno Inc., Company, Information,',
  },
  {
    path: '/en/service',
    title: 'Our Services - Plusuno Inc.',
  },
  {
    path: '/en/job',
    title: 'We Are Hiring - Plusuno Inc.',
  },
  {
    path: '/en/contact',
    title: 'Contact Us - Plusuno Inc.',
  },
]
