import React from 'react'

import Headroom from 'react-headroom'
import { Logo } from '@src/components/atoms/logo'
import { Nav } from '@src/components/nav'

import './style.sass'
// import { HeaderScrollWrapper } from '@src/components/Header/HeaderScrollWrapper'

interface HeaderProps {
  nav: boolean
}
const Header: React.SFC<HeaderProps> = (props) => (
  // <HeaderScrollWrapper>
  <Headroom disableInlineStyles wrapperStyle={{ height: 12 }}>
    <header className="header borderBox">
      <div className="box">
        <div className="row">
          <div className="header-logo">
            <Logo />
          </div>

          {props.nav && (
            <div className="header-nav">
              <Nav />
            </div>
          )}
        </div>
      </div>
    </header>
  </Headroom>
  // </HeaderScrollWrapper>
)

Header.defaultProps = {
  nav: true,
}

// import React from 'react'
// import Link from 'gatsby-link'

// const Header = () => (
//   <div
//     style={{
//       position: 'fixed',
//       top: 0,
//       display: 'flex',
//       flex: 1,
//       borderRadius: 4,
//       background: 'rebeccapurple',
//       marginBottom: '1.45rem',
//     }}
//   >
//     <div
//       style={{
//         margin: '0 auto',
//         maxWidth: 960,
//         padding: '1.45rem 1.0875rem',
//       }}
//     >
//       <h1 style={{ margin: 0 }}>
//         <Link
//           to="/"
//           style={{
//             color: 'white',
//             textDecoration: 'none',
//           }}
//         >
//           Gatsby
//         </Link>
//       </h1>
//       <Link
//         to="/about"
//       >
//         About
//       </Link>
//     </div>
//   </div>
// )

export default Header
