// tslint:disable: member-ordering
type Service = {
  icon: any
  name: string
  description: string
  url: string
  style?: {
    width: number
    height: number
  }
  store: {
    ios?: {
      appStore?: string
    }
    android?: {
      playStore?: string
    }
  }
}
// tslint:enable: member-ordering

export const serviceBabyApp: Service = {
  icon: require('../images/BabyApp-logo.svg'),
  name: 'BabySmile',
  description: 'データ消失を恐れずに、赤ちゃんに遊ばせることのできるアプリ',
  url: 'https://babyapp.plusuno.jp',
  style: { width: 40, height: 40 },
  store: {
    // ios: {appStore: "https://itunes.apple.com/us/app/podcast-1/id1358094409?ls=1&mt=8"},
    android: { playStore: 'https://play.google.com/store/apps/details?id=jp.plusuno.babyapp' },
  },
}
export const servicePodcast: Service = {
  icon: require('../images/PP1-logo.svg'),
  name: 'Podcast +1',
  description:
    '登録しているポッドキャストを無駄な動作なく聞くことができる、シンプルなポッドキャスト用アプリ。',
  url: 'https://www.podcast-plus1.com',
  style: { width: 40, height: 40 },
  store: {
    ios: { appStore: 'https://itunes.apple.com/us/app/podcast-1/id1358094409?ls=1&mt=8' },
    android: { playStore: 'https://play.google.com/store/apps/details?id=com.podcast_plus1' },
  },
}

export const services: Service[] = [serviceBabyApp, servicePodcast]
