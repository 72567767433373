import React from 'react'
import { SEO } from '@src/components/SEO'
import { pages } from '@config/pages'
import { locales, defaultLocale } from '@config/settings'

import Header from '@src/components/Header'
import Footer from '@src/components/Footer'
import '@src/theme/style.sass'
import { IntlProvider } from '@src/components/contexts/Locale'

class Layout extends React.Component<any, any> {
  static defaultProps = {
    nav: true,
  }
  constructor(props: any) {
    super(props)

    // this.state = { locale, seo: { ...page, basePath, locale } }
  }

  componentDidMount() {
    if (!document.getElementById('webfontloader')) {
      ;(function(d) {
        const wf = d.createElement('script'),
          s = d.scripts[0]
        wf.src =
          'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js'
        wf.async = true
        wf.id = 'webfontloader'

        wf.onload = () => {
          WebFont.load({
            google: {
              families: ['Mplus 1p:400,500,700,800'],
            },
          })
        }

        s.parentNode && s.parentNode.insertBefore(wf, s)
      })(document)
    }
  }

  renderContact() {
    return (
      <div>
        <Header nav={false} />
        <main>{this.props.children}</main>
      </div>
    )
  }
  render() {
    const path = this.props.location && this.props.location.pathname
    let page = pages.find((page) => {
      // console.log(page)
      return path === page.path
    })
    process.env.NODE_ENV === 'development' && console.log(this.props)
    process.env.NODE_ENV === 'development' && console.log(path, page)
    // const page = pages.find((page) => page.path === path)
    // const locale = path && path.match(/^\/en/) ? '/en' : ''
    const localeMatch = path.match(
      new RegExp(`^\/(${Object.keys(locales).join('|')})(.*)$`),
    )
    const locale = localeMatch ? localeMatch[1] : defaultLocale
    const basePath = localeMatch ? localeMatch[2] : path
    const { nav } = this.props
    const seo = { ...page, basePath, locale }
    // const { locale, seo } = this.state

    return (
      <React.Fragment>
        <IntlProvider locale={locale}>
          <SEO {...seo} {...{ locale }} />
          {<Header nav={nav} />}

          <main>{this.props.children}</main>

          {nav && <Footer />}
        </IntlProvider>
      </React.Fragment>
    )
  }
}
export { Layout }
