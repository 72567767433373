import React from 'react'
import Link from 'gatsby-link'
import { companyName } from '@config/settings'

import logo from '@src/images/logo.svg'
import { withLocale } from '@src/components/contexts/Locale'
import { locales } from '@config/settings'

import './style.sass'

export const Logo = withLocale((props) => (
  <Link to={`${locales[props.locale]}/`} className="logo">
    <img src={logo} alt={companyName} />
  </Link>
))
