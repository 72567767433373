import React from 'react'
import Helmet from 'react-helmet'
import { siteUrl, locales, facebookAppId, localeType } from '@config/settings'
import logo from '@src/images/logo.png'

export interface SEOProps {
  locale: localeType
  title?: string
  description?: string
  keywords?: string
  path?: string
  basePath: string
}

export const SEO: React.SFC<SEOProps> = ({
  locale,
  title,
  description,
  keywords,
  path,
  basePath,
}) => {
  if (!title) return null

  const url = `${siteUrl}${path}`

  return (
    <Helmet>
      <html lang={locale} />
      <title itemProp="name" lang={locale}>
        {title}
      </title>
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}

      <link rel="canonical" href={url} />
      {Object.keys(locales).map((_locale) => {
        if (_locale !== locale) {
          return (
            <link
              key={_locale}
              rel="alternate"
              href={`${siteUrl}${locales[_locale]}${basePath}`}
              hrefLang={_locale}
            />
          )
        } else return null
      })}

      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={logo} />
      <meta property="og:description" content={description} />
      {facebookAppId && <meta property="fb:app_id" content={facebookAppId} />}
    </Helmet>
  )
}

// const getSchemaOrgJSONLD: React.SFC<SEOProps> = ({
//   isBlogPost,
//   url,
//   title,
//   image,
//   description,
//   datePublished,
// }) => {
//   const schemaOrgJSONLD = [
//     {
//       '@context': 'http://schema.org',
//       '@type': 'WebSite',
//       url,
//       name: title,
//       alternateName: config.title,
//     },
//   ]

//   return isBlogPost
//     ? [
//         ...schemaOrgJSONLD,
//         {
//           '@context': 'http://schema.org',
//           '@type': 'BreadcrumbList',
//           itemListElement: [
//             {
//               '@type': 'ListItem',
//               position: 1,
//               item: {
//                 '@id': url,
//                 name: title,
//                 image,
//               },
//             },
//           ],
//         },
//         {
//           '@context': 'http://schema.org',
//           '@type': 'BlogPosting',
//           url,
//           name: title,
//           alternateName: config.title,
//           headline: title,
//           image: {
//             '@type': 'ImageObject',
//             url: image,
//           },
//           description,
//           author: {
//             '@type': 'Person',
//             name: 'Jason Lengstorf',
//           },
//           publisher: {
//             '@type': 'Organization',
//             url: 'https://lengstorf.com',
//             logo: config.logo,
//             name: 'Jason Lengstorf',
//           },
//           mainEntityOfPage: {
//             '@type': 'WebSite',
//             '@id': config.url,
//           },
//           datePublished,
//         },
//       ]
//     : schemaOrgJSONLD
// }

// const SEO: React.SFC<SEOProps> = ({ postData, postImage, isBlogPost }) => {
//   const postMeta = postData.frontmatter || {}

//   const title = postMeta.title || config.title
//   const description =
//     postMeta.description || postData.excerpt || config.description
//   const image = `${config.url}${postImage}` || config.image
//   const url = postMeta.slug
//     ? `${config.url}${path.sep}${postMeta.slug}`
//     : config.url
//   const datePublished = isBlogPost ? postMeta.datePublished : false

//   const schemaOrgJSONLD = getSchemaOrgJSONLD({
//     isBlogPost,
//     url,
//     title,
//     image,
//     description,
//     datePublished,
//   })

//   return (
//     <Helmet>
//       {/* General tags */}
//       <meta name="description" content={description} />
//       <meta name="image" content={image} />

//       {/* Schema.org tags */}
//       <script type="application/ld+json">
//         {JSON.stringify(schemaOrgJSONLD)}
//       </script>

//       {/* OpenGraph tags */}
//       <meta property="og:url" content={url} />
//       {isBlogPost ? <meta property="og:type" content="article" /> : null}
//       <meta property="og:title" content={title} />
//       <meta property="og:description" content={description} />
//       <meta property="og:image" content={image} />
//       <meta property="fb:app_id" content={config.fbAppID} />

//       {/* Twitter Card tags */}
//       <meta name="twitter:card" content="summary_large_image" />
//       <meta name="twitter:creator" content={config.twitter} />
//       <meta name="twitter:title" content={title} />
//       <meta name="twitter:description" content={description} />
//       <meta name="twitter:image" content={image} />
//     </Helmet>
//   )
// }

// interface SEOProps {
//   isBlogPost?: boolean
//   postData: {
//     frontmatter: any
//     excerpt: any
//   }
//   postImage?: string
//   url: string
//   title: string
//   image: string
//   description: string
//   datePublished: any
// }

// SEO.defaultProps = {
//   isBlogPost: false,
//   postImage: null,
// }

// export default SEO
