export const themeColor = '#45E04F'

export const colors = {
  white: '#ffffff',
  green: themeColor,
  green50: 'rgba(69,224,79, 0.5)',
  darkGreen: '#18291A',
  deepGreen: '#3E7344',
  deepGreen50: 'rgba(62,115,68,0.5)',
  formPlaceholderTextColor: '#BCDCC0',
  mapPin: '#009D46',
  grey: '#E3E3E3',
  lightGray: '#F6F6F6',
  ultraLightGray: '#FAFAFA',
  background: '#FAFAFA',
  theme: themeColor,
}
