import { companyName } from '@config/settings'
import { services } from '@src/config/services'
import Link from 'gatsby-link'
import React from 'react'
import './style.sass'

const footerListItemIcons = {
  'Our Service': services.map((service) => ({
    icon: service.icon,
    title: service.name,
    linkTo: service.url,
    style: service.style,
  })),
  'Find Us Here': [
    {
      icon: require('../../images/Facebook.svg'),
      title: 'Facebook',
      linkTo: 'https://www.facebook.com/plusuno.org/',
      style: { width: 34, height: 34 },
    },
    {
      icon: require('../../images/Twitter.svg'),
      title: 'Twitter',
      linkTo: 'https://twitter.com/plusuno_org',
      style: { width: 34, height: 28 },
    },
    // {
    //   icon: require("../../images/GooglePlus.svg"),
    //   title: "Google+",
    //   linkTo: "",
    //   style: { width: 47, height: 30 }
    // },
  ],
}

const FooterContentListItem: React.FC<{
  icon: string
  linkTo: string
  style: React.CSSProperties
  title: string
}> = (props) => (
  <div className="footerContentListItem" style={props.style}>
    <a href={props.linkTo}>
      <img src={props.icon} title={props.title} alt={props.title} />
    </a>
  </div>
)
const Copyright = () => (
  <div
    className="Copyright"
    style={{
      backgroundColor: '#272727',
      textAlign: 'center',
      paddingTop: 24,
      paddingBottom: 24,
      color: 'white',
    }}
  >
    <Link
      to="/"
      style={{
        color: 'white',
        fontSize: 16,
        textDecoration: 'none',
      }}
    >
      © {companyName}
    </Link>
  </div>
)
const FooterContent = () => (
  <div
    className="footerContentsBox"
    style={{
      backgroundColor: '#575757',
    }}
  >
    {Object.keys(footerListItemIcons).map((key, index) => (
      <div
        className="footerContentBox"
        key={index}
        // style={{
        //   textAlign: 'center'
        // }}
      >
        <h4>{key}</h4>
        <div className="footerContentList">
          {footerListItemIcons[key].map((item, index) => (
            <FooterContentListItem {...item} key={index} />
          ))}
        </div>
      </div>
    ))}
  </div>
  // <div
  //   className="footerContentBox"
  //   // style={{
  //   //   textAlign: 'center'
  //   // }}
  // >
  //   <h4>Our Service</h4>
  //   <div className="footerContentList">
  //     <FooterContentListItem icon={require("../../images/PP1-logo.svg")} title="Podcast +1" linkTo="" style={} />
  //     <div className="footerContentListItem">Podcast +1</div>
  //   </div>
  // </div>
  // <div className="footerContentBox">
  //   <h4>Find Us Here</h4>
  //   <div className="footerContentList">
  //     <FooterContentListItem icon={require("../../images/Facebook.svg")} title="Facebook" linkTo="" />
  //     <FooterContentListItem icon={require("../../images/Twitter.svg")} title="Twitter" linkTo="" />
  //     <FooterContentListItem icon={require("../../images/GooglePlus.svg")} title="Google+" linkTo="" />
  //   </div>
  // </div>
)

const Footer = () => (
  <footer className="footer">
    <div
      style={{
        height: 10,
        backgroundColor: '#272727',
      }}
    />
    <FooterContent />
    <Copyright />
  </footer>
)

export default Footer
