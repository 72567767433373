// https://reactjs.org/docs/context.html

import React, { useContext } from 'react'

type WithLocaleProps = {
  locale: string
}
type IntlProviderState = WithLocaleProps & {
  changeLocale: (locale: string) => void
}

const IntlContext = React.createContext<IntlProviderState>({
  locale: 'ja',
  changeLocale: (locale: string) => {
    locale
  },
})
export const { Provider, Consumer } = IntlContext

export class IntlProvider extends React.Component<any, IntlProviderState> {
  constructor(props: any) {
    super(props)
    // State also contains the updater function so it will
    // be passed down into the context provider
    this.state = {
      locale: props.locale ? props.locale : 'ja',
      changeLocale: this.changeLocale,
    }
  }

  changeLocale = (locale: string) => {
    this.setState({ locale })
  }

  render() {
    // The entire state is passed to the provider
    return <Provider value={this.state}>{this.props.children}</Provider>
  }
}
export function useIntlContext(): IntlProviderState {
  return useContext(IntlContext)
}

export const withLocale = <P extends object>(
  Component: React.ComponentType<P & IntlProviderState>,
) => (props: P) => (
  <Consumer>
    {({ locale, changeLocale }) => (
      <Component locale={locale} changeLocale={changeLocale} {...props} />
    )}
  </Consumer>
)
