import React from 'react'
import Link from 'gatsby-link'
import { withLocale } from '@src/components/contexts/Locale'
import { locales } from '@config/settings'

// https://reach.tech/router/api/Link

const NavListItem = (props: any) => (
  <li>
    <Link to={props.to} activeClassName="is-active">
      {props.text}
    </Link>
  </li>
)

const navs = [
  { to: '/', text: 'Home' },
  { to: '/service', text: 'Service' },
  { to: '/company', text: 'Company' },
  // { to: '/job', text: 'Job' },
  { to: '/contact', text: 'Contact' },
]

const Nav = (props: any) => (
  <nav className="nav">
    <ul>
      {navs.map((nav, index) => (
        <NavListItem to={`${locales[props.locale]}${nav.to}`} text={nav.text} key={index} />
      ))}
    </ul>
  </nav>
  // <NavListItem to="/" text="Home" />
  // <NavListItem to="/service" text="Service" />
  // <NavListItem to="/company" text="Company" />
  // <NavListItem to="/job" text="Job" />
  // <NavListItem to="/contact" text="Contact" />
)

const NavWithLocale = withLocale(Nav)

export { NavWithLocale as Nav }
